// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_cw-button__XyNeY {
  border-radius: 5px;
  font-size: 0.9rem;
  padding: 8px 22px;
  transition: all 0.3s;
  position: relative;
}

.Button_spinner__a0HpW {
  height: 0.9rem;
  width: 0.9rem;
  position: absolute;
  right: calc(50% - 0.45rem);
  top: calc(50% - 0.45rem);
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,0BAA0B;EAC1B,wBAAwB;AAC1B","sourcesContent":[".cw-button {\n  border-radius: 5px;\n  font-size: 0.9rem;\n  padding: 8px 22px;\n  transition: all 0.3s;\n  position: relative;\n}\n\n.spinner {\n  height: 0.9rem;\n  width: 0.9rem;\n  position: absolute;\n  right: calc(50% - 0.45rem);\n  top: calc(50% - 0.45rem);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cw-button": `Button_cw-button__XyNeY`,
	"spinner": `Button_spinner__a0HpW`
};
export default ___CSS_LOADER_EXPORT___;
