// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputGroup_group__wsphG {
  text-align: left;
  margin-bottom: 1rem;

  .InputGroup_label__QXhQx {
    font-family: SegoeUI;
    font-size: 0.75rem;
    font-weight: 600;
    color: #464646;
    margin-bottom: 0;
  }

  .InputGroup_field__N0efc,
  input {
    font-size: 0.75rem;
    color: #202020;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/InputGroup/index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;;EAEnB;IACE,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,gBAAgB;EAClB;;EAEA;;IAEE,kBAAkB;IAClB,cAAc;EAChB;AACF","sourcesContent":[".group {\n  text-align: left;\n  margin-bottom: 1rem;\n\n  .label {\n    font-family: SegoeUI;\n    font-size: 0.75rem;\n    font-weight: 600;\n    color: #464646;\n    margin-bottom: 0;\n  }\n\n  .field,\n  input {\n    font-size: 0.75rem;\n    color: #202020;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": `InputGroup_group__wsphG`,
	"label": `InputGroup_label__QXhQx`,
	"field": `InputGroup_field__N0efc`
};
export default ___CSS_LOADER_EXPORT___;
