export const IDENTITY = {
  LOGIN: "/login",
  SIGN_UP: "/signup",
  LOGOUT: "/logout",
  FEADBACK: "/feadback",
  FORGET_PASSWORD: "/forget-password",
  FORGET_PASSWORD_SUCCESS: "/forget-password-success",
  RESET_PASSWORD: '/reset-password'
}


const SCREENS = {
  ...IDENTITY,
  PRODUCTS: "/products",

};

export default SCREENS;
