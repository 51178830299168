// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForgotPasswordSuccess_container__T7uTY {
  padding-top: 4rem;
}

.ForgotPasswordSuccess_segment__\\+qB7v {
  margin: auto;
  margin-top: 4rem;
  background-color: white;
  padding: 2rem;
  border-radius: 4px;

  img {
    margin: auto;
    width: 104px;
    height: 58px;
  }

  p {
    margin-top: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ForgotPasswordSuccess/index.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;;EAElB;IACE,YAAY;IACZ,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".container {\n  padding-top: 4rem;\n}\n\n.segment {\n  margin: auto;\n  margin-top: 4rem;\n  background-color: white;\n  padding: 2rem;\n  border-radius: 4px;\n\n  img {\n    margin: auto;\n    width: 104px;\n    height: 58px;\n  }\n\n  p {\n    margin-top: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ForgotPasswordSuccess_container__T7uTY`,
	"segment": `ForgotPasswordSuccess_segment__+qB7v`
};
export default ___CSS_LOADER_EXPORT___;
