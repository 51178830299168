// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desert-navbar {
  background: #e5e5ee;
  text-align: left !important;
  height: 90px;
}
.desert-navbar .logo {
  margin-left: 70px;
  padding-top: 15px;
  display: inline-block;
}

.desert-navbar .logo .desert-logo {
  width: 250px;
  height: 60px;
}

.cart {
  position: relative;
  font-size: 1.2rem;
  margin-right: 1rem;
}
.cart-icon {
  cursor: pointer;
}

.number-of-items {
  position: absolute;
  top: -6px;
  right: -18px;
  color: #c14679;
}

.user {
  button {
    background: none !important;
    box-shadow: none !important;
    color: black !important;
    border: none !important;
  }
}

.search {
  position: relative;
  width: 70%;
  margin: auto;
  svg {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

@media (max-width: 575.98px) {
  .search {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/navbar/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2BAA2B;EAC3B,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE;IACE,2BAA2B;IAC3B,2BAA2B;IAC3B,uBAAuB;IACvB,uBAAuB;EACzB;AACF;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ;IACE,kBAAkB;IAClB,SAAS;IACT,UAAU;EACZ;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".desert-navbar {\n  background: #e5e5ee;\n  text-align: left !important;\n  height: 90px;\n}\n.desert-navbar .logo {\n  margin-left: 70px;\n  padding-top: 15px;\n  display: inline-block;\n}\n\n.desert-navbar .logo .desert-logo {\n  width: 250px;\n  height: 60px;\n}\n\n.cart {\n  position: relative;\n  font-size: 1.2rem;\n  margin-right: 1rem;\n}\n.cart-icon {\n  cursor: pointer;\n}\n\n.number-of-items {\n  position: absolute;\n  top: -6px;\n  right: -18px;\n  color: #c14679;\n}\n\n.user {\n  button {\n    background: none !important;\n    box-shadow: none !important;\n    color: black !important;\n    border: none !important;\n  }\n}\n\n.search {\n  position: relative;\n  width: 70%;\n  margin: auto;\n  svg {\n    position: absolute;\n    top: 10px;\n    left: 10px;\n  }\n}\n\n@media (max-width: 575.98px) {\n  .search {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
