// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignupForm_form__m\\+Xb0 {
  max-width: 688px;
  height: 792px;
  margin: auto;
  margin-top: 4rem;
  padding-top: 56px;
  padding-bottom: 75px;
  padding-left: 104px;
  padding-right: 104px;
  position: relative;
  background-color: #fefefe;

  .SignupForm_header__bR9xG {
    color: #353940;

    font-size: 26px;
    letter-spacing: 0.52px;
    margin-top: 25px;
  }
  .SignupForm_showPasswordBtn__laPvP{
    margin-left: 30rem;
  }
  .SignupForm_desc__hU5KG {
    color: #616978;

    font-size: 14px;
    letter-spacing: 0.32px;
    margin-bottom: 40px;
  }

  .SignupForm_label__VsjvF {
    color: #26282a;
  }

  button[type="submit"] {
    display: block;
    width: 80%;
    outline: none;
    border: none;
    margin-top: 68px;
  }

  .intl-tel-input {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Signup/components/SignupForm.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,yBAAyB;;EAEzB;IACE,cAAc;;IAEd,eAAe;IACf,sBAAsB;IACtB,gBAAgB;EAClB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,cAAc;;IAEd,eAAe;IACf,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,UAAU;IACV,aAAa;IACb,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".form {\n  max-width: 688px;\n  height: 792px;\n  margin: auto;\n  margin-top: 4rem;\n  padding-top: 56px;\n  padding-bottom: 75px;\n  padding-left: 104px;\n  padding-right: 104px;\n  position: relative;\n  background-color: #fefefe;\n\n  .header {\n    color: #353940;\n\n    font-size: 26px;\n    letter-spacing: 0.52px;\n    margin-top: 25px;\n  }\n  .showPasswordBtn{\n    margin-left: 30rem;\n  }\n  .desc {\n    color: #616978;\n\n    font-size: 14px;\n    letter-spacing: 0.32px;\n    margin-bottom: 40px;\n  }\n\n  .label {\n    color: #26282a;\n  }\n\n  button[type=\"submit\"] {\n    display: block;\n    width: 80%;\n    outline: none;\n    border: none;\n    margin-top: 68px;\n  }\n\n  :global(.intl-tel-input) {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `SignupForm_form__m+Xb0`,
	"header": `SignupForm_header__bR9xG`,
	"showPasswordBtn": `SignupForm_showPasswordBtn__laPvP`,
	"desc": `SignupForm_desc__hU5KG`,
	"label": `SignupForm_label__VsjvF`
};
export default ___CSS_LOADER_EXPORT___;
