// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgot-password-form_form__zW78H {
  max-width: 22rem;
  margin: auto;
  max-width: 688px;
  height: 1000px;
  margin: auto;
  margin-top: 4rem;
  padding-top: 56px;
  padding-bottom: 75px;
  padding-left: 104px;
  padding-right: 104px;
  position: relative;
  background-color: #fefefe;

  button[type="submit"] {
    margin-top: 1rem;
    display: block;
    width: 100%;
    outline: none;
    border: none;
  }
}

.forgot-password-form_header__yKlu0 {
  font-size: 1.5rem;
}

.forgot-password-form_desc__TjzyB {
  color: #666666;
  font-size: 0.75rem;
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ForgotPassword/components/forgot-password-form.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,yBAAyB;;EAEzB;IACE,gBAAgB;IAChB,cAAc;IACd,WAAW;IACX,aAAa;IACb,YAAY;EACd;AACF;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".form {\n  max-width: 22rem;\n  margin: auto;\n  max-width: 688px;\n  height: 1000px;\n  margin: auto;\n  margin-top: 4rem;\n  padding-top: 56px;\n  padding-bottom: 75px;\n  padding-left: 104px;\n  padding-right: 104px;\n  position: relative;\n  background-color: #fefefe;\n\n  button[type=\"submit\"] {\n    margin-top: 1rem;\n    display: block;\n    width: 100%;\n    outline: none;\n    border: none;\n  }\n}\n\n.header {\n  font-size: 1.5rem;\n}\n\n.desc {\n  color: #666666;\n  font-size: 0.75rem;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `forgot-password-form_form__zW78H`,
	"header": `forgot-password-form_header__yKlu0`,
	"desc": `forgot-password-form_desc__TjzyB`
};
export default ___CSS_LOADER_EXPORT___;
