// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhoneNumber_container__9Oo-1 {
  width: 100%;

  input.form-control {
    width: 100%;
    font-size: 0.75rem;
    height: 32px;
  }
  .PhoneNumber_label__EqaAu {
    font-size: 0.7rem;
    color: #999999;
    margin-bottom: 0;
  }

  &.PhoneNumber_disabled__hHRem {
    * {
      cursor: not-allowed;
    }
    input {
      background-color: #e9ecef;
      cursor: default;
    }
  }
  .is-invalid {
    box-shadow: 0 0 0 1px #dc3545 !important;

    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/inputs/PhoneNumber/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;;EAEX;IACE,WAAW;IACX,kBAAkB;IAClB,YAAY;EACd;EACA;IACE,iBAAiB;IACjB,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE;MACE,mBAAmB;IACrB;IACA;MACE,yBAAyB;MACzB,eAAe;IACjB;EACF;EACA;IACE,wCAAwC;;IAExC,yDAA4U;IAC5U,4BAA4B;IAC5B,2DAA2D;IAC3D,gEAAgE;EAClE;AACF","sourcesContent":[".container {\n  width: 100%;\n\n  input:global(.form-control) {\n    width: 100%;\n    font-size: 0.75rem;\n    height: 32px;\n  }\n  .label {\n    font-size: 0.7rem;\n    color: #999999;\n    margin-bottom: 0;\n  }\n\n  &.disabled {\n    * {\n      cursor: not-allowed;\n    }\n    input {\n      background-color: #e9ecef;\n      cursor: default;\n    }\n  }\n  :global(.is-invalid) {\n    box-shadow: 0 0 0 1px #dc3545 !important;\n\n    background-image: url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e\");\n    background-repeat: no-repeat;\n    background-position: right calc(0.375em + 0.1875rem) center;\n    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PhoneNumber_container__9Oo-1`,
	"label": `PhoneNumber_label__EqaAu`,
	"disabled": `PhoneNumber_disabled__hHRem`
};
export default ___CSS_LOADER_EXPORT___;
