// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cross-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9999;
}
.fa-times {
  font-size: 24px;
  color: #666;
  cursor: pointer;
}

.fa-times:hover {
  color: #333;
}

.card {
  margin: 0 30px 20px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-header {
  padding: 15px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 8px 8px 0 0;
}

.card-header h5 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.card-body {
  padding: 15px;
}

.card-body p {
  margin: 5px 0;
}

.list-group-item {
  border: none;
}

.list-group-item:last-child {
  border-bottom: none;
}

.table {
  margin-bottom: 0;
}

.table th {
  font-size: 16px;
  font-weight: 500;
  color: #777777;
  border-top: none;
}

.table td {
  font-size: 16px;
  vertical-align: middle;
  border-top: none;
}

.table tbody tr:hover {
  background-color: #f8f9fa;
}

.table tbody td {
  padding: 10px 0;
}

.table tbody td:first-child {
  text-align: left;
}

.table tbody td:last-child {
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/order/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,aAAa;AACf;AACA;EACE,eAAe;EACf,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,gCAAgC;EAChC,0BAA0B;AAC5B;;AAEA;EACE,SAAS;EACT,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".cross-icon {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  z-index: 9999;\n}\n.fa-times {\n  font-size: 24px;\n  color: #666;\n  cursor: pointer;\n}\n\n.fa-times:hover {\n  color: #333;\n}\n\n.card {\n  margin: 0 30px 20px;\n  border: 1px solid #e1e1e1;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.card-header {\n  padding: 15px;\n  background-color: #f8f9fa;\n  border-bottom: 1px solid #e1e1e1;\n  border-radius: 8px 8px 0 0;\n}\n\n.card-header h5 {\n  margin: 0;\n  font-size: 18px;\n  font-weight: 500;\n}\n\n.card-body {\n  padding: 15px;\n}\n\n.card-body p {\n  margin: 5px 0;\n}\n\n.list-group-item {\n  border: none;\n}\n\n.list-group-item:last-child {\n  border-bottom: none;\n}\n\n.table {\n  margin-bottom: 0;\n}\n\n.table th {\n  font-size: 16px;\n  font-weight: 500;\n  color: #777777;\n  border-top: none;\n}\n\n.table td {\n  font-size: 16px;\n  vertical-align: middle;\n  border-top: none;\n}\n\n.table tbody tr:hover {\n  background-color: #f8f9fa;\n}\n\n.table tbody td {\n  padding: 10px 0;\n}\n\n.table tbody td:first-child {\n  text-align: left;\n}\n\n.table tbody td:last-child {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
