import React from 'react';

const Feadback = () => {
  return (
    <div>
      <h1>About Us</h1>
      <p>Contact us at:</p>
      <ul>
        <li>Email: admin@vtbazaar.net</li>
        <li>Phone: 86258 79347</li>
        <li>For customer feedback: No</li>
      </ul>
    </div>
  );
};

export default Feadback;