// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_container__L1O\\+i {
  height: 100%;
  flex-shrink: 1;

  .Layout_mainContainer__tQZnt {
    margin-top: 3.2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;;EAEd;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".container {\n  height: 100%;\n  flex-shrink: 1;\n\n  .mainContainer {\n    margin-top: 3.2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Layout_container__L1O+i`,
	"mainContainer": `Layout_mainContainer__tQZnt`
};
export default ___CSS_LOADER_EXPORT___;
