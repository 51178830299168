// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  text-align: left;
}
.check .cart-content {
  margin-top: 45px;
}

.close-button {
  position: relative;
  overflow: hidden;
  font-size: 14px;
  line-height: 2;
  border: 2px solid #d2d3d4;
  border-radius: 6px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  background-color: #f8f9fa;
  color: black;
  outline: none;
  z-index: 1;
  padding: 15px 20px;
  min-width: 190px;
}
.close-button:hover,
.close-button:active {
  color: white;
  background-color: #c14679;
  border-color: #c14679;
}
.close-button:hover:after {
  transform: translate3d(-50%, -50%, 0) scale(0);
}
.close-button::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 110%;
  padding-bottom: 110%;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0) scale(1);
  transition: inherit;
  pointer-events: none;
  z-index: -1;
  border-color: #a43664;
  background-color: #f8f9fa;
}

@media (max-width: 767.98px) {
  .close-button {
    min-width: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/check-out/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;EACrB,yBAAyB;EACzB,gCAAgC;EAChC,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,UAAU;EACV,kBAAkB;EAClB,gBAAgB;AAClB;AACA;;EAEE,YAAY;EACZ,yBAAyB;EACzB,qBAAqB;AACvB;AACA;EACE,8CAA8C;AAChD;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,SAAS;EACT,WAAW;EACX,oBAAoB;EACpB,kBAAkB;EAClB,8CAA8C;EAC9C,mBAAmB;EACnB,oBAAoB;EACpB,WAAW;EACX,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["form {\n  text-align: left;\n}\n.check .cart-content {\n  margin-top: 45px;\n}\n\n.close-button {\n  position: relative;\n  overflow: hidden;\n  font-size: 14px;\n  line-height: 2;\n  border: 2px solid #d2d3d4;\n  border-radius: 6px;\n  font-weight: 700;\n  letter-spacing: 0.1em;\n  text-transform: uppercase;\n  transition: all 0.3s ease-in-out;\n  background-color: #f8f9fa;\n  color: black;\n  outline: none;\n  z-index: 1;\n  padding: 15px 20px;\n  min-width: 190px;\n}\n.close-button:hover,\n.close-button:active {\n  color: white;\n  background-color: #c14679;\n  border-color: #c14679;\n}\n.close-button:hover:after {\n  transform: translate3d(-50%, -50%, 0) scale(0);\n}\n.close-button::after {\n  position: absolute;\n  content: \"\";\n  top: 50%;\n  left: 50%;\n  width: 110%;\n  padding-bottom: 110%;\n  border-radius: 50%;\n  transform: translate3d(-50%, -50%, 0) scale(1);\n  transition: inherit;\n  pointer-events: none;\n  z-index: -1;\n  border-color: #a43664;\n  background-color: #f8f9fa;\n}\n\n@media (max-width: 767.98px) {\n  .close-button {\n    min-width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
